import { debounce } from '../helper/global.js';

class BlogSearch extends HTMLElement {
  constructor() {
    super();
    this.handleSearch = debounce(this.performSearch.bind(this), 350);
  }

  connectedCallback() {
    this.input = this.querySelector('input');

    if (this.input) {
      this.input.addEventListener('input', this.handleSearch);
    } else {
      console.error('Input element not found');
    }
  }

  performSearch() {
    const search = this.input;
    let query = search.value;
    const searchTerm = search.value.trim();
    if (searchTerm !== '') {
      query = `/search?q=${searchTerm}`;
    } else {
      query = window.location.pathname;
    }

    fetch(query)
      .then((response) => response.text())
      .then((responseText) => {
        const html = responseText;
        const parsedHTML = new DOMParser().parseFromString(html, 'text/html');

        const blogGrid = document.getElementById('filtered-wrapper');
        const parsedArticleGrid = parsedHTML.getElementById('filtered-wrapper');

        const viewMoreButton = parsedArticleGrid.querySelector('#article-view-more');

        if (viewMoreButton) {
          viewMoreButton.remove();
        }

        const parsedArticles = Array.from(parsedArticleGrid.querySelectorAll('article'));

        parsedArticles.forEach((element) => {
          element.parentElement.classList.remove('hidden-article');
        });

        const existingSearchHeader = blogGrid.querySelector('.search-header');
        if (existingSearchHeader) {
          existingSearchHeader.remove();
        }

        if (searchTerm !== '') {
          const searchTermDiv = document.createElement('div');
          searchTermDiv.textContent = `Search Results for: '${searchTerm}'`;
          searchTermDiv.classList.add(
            'search-header',
            'w-full',
            'text-sm',
            'py-10',
            'text-center',
            'font-sans',
            'font-bold',
            'uppercase'
          );
          parsedArticleGrid.prepend(searchTermDiv);
        }

        blogGrid.innerHTML = parsedArticleGrid.innerHTML;
      })
      .catch((error) => {
        console.error('Error during search:', error);
      });
  }

  disconnectedCallback() {
    if (this.input) {
      this.input.removeEventListener('input', this.handleSearch);
    }
  }
}

customElements.define('blog-search', BlogSearch);
